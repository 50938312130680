@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pause {
        animation-play-state: paused;
    }
}

@font-face {
    font-family: "Tenada";
    src: url("../fonts/Tenada.ttf");
}

@font-face {
    font-family: "AppleSDGothicNeoL";
    src: url("../fonts/AppleSDGothicNeoL.ttf");
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}

::-moz-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
}

::-moz-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}

::-ms-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
}

::-ms-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}

::scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
}

::scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}


// Swiper custom styles
.swiper-wrapper {
    transition-timing-function: linear;
    width: 100%;
}

.swiper-slide {
    width: fit-content;
    backface-visibility: visible;
}

.swiper-pagination {
    //in mobile
    @media all and (max-width: 999px) {
        top: 85% !important;
    }
    //in tablet
    @media all and (min-width: 1000px) and (max-width: 1599px) {
        top: 85% !important;
    }
    //in desktop
    @media all and (min-width: 1600px) {
        top: 80% !important;
    }
    left: 25% !important;
    background-color: white;
    width: 50% !important;
    height: 0.1vh !important;
}

.swiper-pagination-progressbar-fill {
    position: absolute !important;
    height: 0.3vh !important;
    top: -50% !important;
    background-color: white !important;
    transition-timing-function: linear !important;
    animation: slide linear infinite !important;
    transition-duration: 1s !important;
}

body {
    background-color: black;
}
